import { white } from 'src/styles/colors'
import styled from 'styled-components'
import { rounded } from '../../../styles/rounded'

export const RangeInputContainer = styled.div`
  height: 35px;
  width: 100%;
  position: relative;
  
  input[type=range]::-moz-range-track {
    height: 8px;
  }
  
  input[type='range'] {
    width: 100%;
  }

  .slider {
    border-radius: 5px;
    height: 8px;
    outline: none;
    padding: 0;
    transition: opacity .2s;
    -webkit-appearance: none; // não tirar
    -webkit-transition: .2s;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    background: ${white};
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    border-radius: ${rounded['50']};
    box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .slider::-moz-range-thumb {
    border-radius: ${rounded['50']};
    background: ${white};
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`
