import axios from 'axios'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as URLs from '../../../../config/api/Urls'
import { getParameterByName } from '../../../../shared/helpers'

import useDataLayer from '../../../../hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from '../../../../hooks/useDataLayer/typesUseDataLayer'

import intervalJSON from './assets/data/interval.json'
import PercentageInterWinJSON from './assets/data/percentageInterWin.json'

import RangeInput from 'src/components/RangeInput/Form'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import formError from './assets/images/error.png'
import QrCodeInterWin from './assets/images/qrcode-inter-win.png'
import formSuccess from './assets/images/success.png'

import { CloseButton, Container, SendButton } from './style'

type PortifolioRightFormProps = {
  closeModal: boolean;
  setIsModalOpen: Function;
  dataLayer: IDataLayerParams;
  customOrigin: string;
}
interface IFormValues {
  nome: string;
  sobrenome: string;
  email: string;
  telefone: string;
  cep: string;
  cpf: string;
  datanascimento: string;
  cliente?: string;
  range?: string;
  Campanha: string;
}

function OpportunitiesForm ({ closeModal, setIsModalOpen, dataLayer, customOrigin }: PortifolioRightFormProps) {
  const { register, errors, handleSubmit, setValue }: Pick<ReturnType<typeof useForm>, 'register' | 'errors' | 'handleSubmit' | 'setValue'> = useForm()
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ acceptedTerms, setAcceptedTerms ] = useState(false)
  const [ rangeValue, setRangeValue ] = useState<number>(0)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)
  const [ cep, setCep ] = useState<string>('')
  const [ isClient, setIsClient ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setLoading(true)

    const formData = [
      {
        ...data,
        telefone: data.telefone.replace(/\D/g, ''),
        cep: data.cep.replace(/\D/g, ''),
        cpf: data.cpf.replace(/\D/g, ''),
        aceito: acceptedTerms,
        investir: data.range,
        utmCampaign: utmCampaign?.toString(),
        utmMedium: utmMedium?.toString(),
        utmSource: utmSource?.toString(),
        utmContent: utmContent?.toString(),
        Campanha: customOrigin,
      },
    ]

    if (formData[0].range) { delete formData[0].range }

    try {
      await axios.post(URLs.CONTACT_FORM_POST_V7, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      setSent(true)
      sendDatalayerEvent({
        ...dataLayer,
        section: dataLayer ? 'm_' + dataLayer.section : 'm_dobra_0',
        section_name: dataLayer ? dataLayer.section_name : '',
        element_name: 'Continuar',
        element_action: 'submit',
        element_previous: dataLayer.element_previous,
        step: 'success',
      })
    } catch (e) {
      setError(true)
      setLoading(false)
    }
  }

  const changeRange = (rangeValueInput: string) => {
    setRangeValue(parseInt(rangeValueInput))
  }

  function handleCheck (event: ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    setAcceptedTerms(inputValue)
  }

  const handleClient = () => {
    setIsClient(true)
    setSent(true)
  }

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-4' onClick={() => setIsModalOpen(false)}>
      <OrangeIcon size='MD' icon='exit' color='#ff7a00' />
    </CloseButton>
  )

  return (
    <>
      {error && (
        <Container isModal={closeModal} className='d-flex align-items-center'>
          {closeButton}
          <div className='col-12 px-5'>
            <div className='col-12 mx-auto text-center mb-4'>
              <img src={formError} width='64' height='64' alt='Imagem de error' />
            </div>
            <h3 className='fs-24 lh-30 fs-md-32 lh-md-40 response-title fw-600 text-center'>
              Não foi possível completar sua solicitação
            </h3>

            <p className='fs-14 lh-16 text-grayscale--400 text-center '>
              Ocorreu um erro no momento de enviar a sua solicitação.
              Você pode tentar novamente em alguns instantes.
            </p>
          </div>
        </Container>
      )}

      {sent && (
        <Container isModal={closeModal} className='d-flex align-items-center justify-content-center'>
          {closeButton}
          <div className='col-12 col-md-10 px-2 px-md-5'>
            <div className='texts text-center text-md-left'>
              <div className='text-center mb-4 d-md-none'>
                <img src={formSuccess} width='64' height='64' alt='Sucesso na requisição' className='mx-auto' />
              </div>

              {isClient ? (
                <span className='fs-14 lh-16 success-title text-grayscale--400'>
                  Prontinho! Recebemos os seus dados.
                </span>
              ) : (
                <span className='fs-14 lh-16 success-title text-grayscale--400'>
                  Prontinho! Recebemos os seus dados. Entraremos em contato em breve.
                </span>
              )}

              {isClient ? (
                <h3 className='fs-24 lh-30 fw-600 success-text'>
                  <span className='d-block d-md-inline'>
                    Você já pode{' '}
                    <a
                      href='https://inter-co.onelink.me/Qyu7/1v0uq70o'
                      target='_blank'
                      rel='noreferrer'
                      className='success-text--orange d-md-none'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: dataLayer.section,
                          section_name: dataLayer.section_name,
                          element_action: 'click button',
                          element_name: 'acessar o app',
                          element_previous: 'Já sou cliente Inter',
                          redirect_url: 'https://inter-co.onelink.me/Qyu7/1v0uq70o',
                        })
                      }}
                    >
                      acessar o
                    </a>
                    <span className='d-none d-md-inline success-text--orange'>acessar o</span>
                  </span>
                  <span className='d-block d-md-inline'>
                    <span className='success-text--orange'> app</span> e fazer seus {' '}
                  </span>
                  investimentos pra se tornar Inter WIN.
                </h3>
              ) : (
                <h3 className='fs-24 lh-30 fw-600 success-text'>
                  <span className='d-block'>
                    Agora{' '}
                    <a
                      href='https://inter-co.onelink.me/Qyu7/1v0uq70o'
                      target='_blank'
                      rel='noreferrer'
                      className='success-text--orange d-md-none'
                      onClick={() => {
                        sendDatalayerEvent({
                          section: dataLayer.section,
                          section_name: dataLayer.section_name,
                          element_action: 'click button',
                          element_name: 'acessar o app',
                          element_previous: 'Já sou cliente Inter',
                          redirect_url: 'https://inter-co.onelink.me/Qyu7/1v0uq70o',
                        })
                      }}
                    >
                      baixe nosso app
                    </a>
                    <span className='d-none d-md-inline success-text--orange'>baixe nosso app</span>
                  </span>
                  <span className='d-block'>
                    pra se tornar Inter WIN e {' '}
                  </span>
                  aproveitar os benefícios.
                </h3>
              )}

              <div className='text-center d-none d-md-block'>
                <img src={QrCodeInterWin} width='185' height='187' alt='qrCode baixar o APP' className='mx-auto' />
              </div>

              <div className='d-none d-md-block'>
                <hr className='line' />
                <p className='fs-14 lh-17 fw-400 success-paragraph'>
                  Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e clicar em ”Abrir conta completa e gratuita”. É rápido e fácil!
                </p>
              </div>
            </div>
          </div>
        </Container>
      )}

      <Container isModal={closeModal} className={`${error || sent ? 'd-none' : 'd-flex'} align-items-start overflow-auto`}>
        {closeButton}
        <div className='form-solicite-contato'>
          <div className='col-12 mb-4 mb-md-5 mb-lg-4 pl-0 px-0'>
            <h3 className='fs-32 lh-40 fw-600 text-grayscale-500 d-none d-md-block'>
              Para saber mais, entre em contato conosco.
            </h3>
            <h3 className='fs-20 lh-25 fw-600 text-grayscale-500 d-md-none'>
              Quer se tornar Inter WIN?
            </h3>
          </div>
          <form
            className='solicite-contato form-hooks'
            onSubmit={handleSubmit(sendForm)}
          >
            <div className='row'>
              <div className='col-12 col-md-6 mb-3'>
                <div className={errors.nome ? 'error' : ''}>
                  <label htmlFor='Name' className='fs-14 lh-18 fw-600 text-grayscale-400 d-block'>Nome</label>
                  <input
                    ref={
                      register({
                        required: 'Nome obrigatório',
                      })
                    }
                    name='nome'
                    id='name'
                    type='text'
                    maxLength={80}
                    placeholder='Digite o seu nome'
                  />
                  {errors.nome && <p className='fs-12 mt-1 mb-0 text-erro'>{errors.nome.message}</p>}
                </div>
              </div>

              <div className='col-12 col-md-6 mb-3'>
                <div className={errors.sobrenome ? 'error' : ''}>
                  <label htmlFor='Sobrenome' className='fs-14 lh-18 fw-600 text-grayscale-400 d-block'>Sobrenome</label>
                  <input
                    ref={
                      register({
                        required: 'Sobrenome obrigatório',
                      })
                    }
                    name='sobrenome'
                    id='sobrenome'
                    type='text'
                    maxLength={80}
                    placeholder='Digite o seu sobrenome'
                  />
                  {errors.sobrenome && <p className='fs-12 mt-1 mb-0 text-erro'>{errors.sobrenome.message}</p>}
                </div>
              </div>

              <div className='col-12 col-md-6 mb-3'>
                <div className={errors.cpf ? 'error' : ''}>
                  <label htmlFor='cpf' className='fs-14 lh-18 fw-600 text-grayscale-400 d-block'>CPF</label>
                  <input
                    ref={register({
                      required: 'CPF obrigatório',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name='cpf'
                    id='cpf'
                    type='tel'
                    placeholder='Digite apenas números'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                  />
                  {errors.cpf && <p className='fs-14 text-red--base mb-0'>{errors.cpf.message}</p>}
                </div>
              </div>

              <div className='col-12 col-md-6 mb-3'>
                <div className={errors.telefone ? 'error' : ''}>
                  <label htmlFor='Phone' className='fs-14 lh-18 fw-600 text-grayscale-400 d-block'>Telefone</label>
                  <input
                    ref={register({
                      required: 'Telefone obrigatório',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    name='telefone'
                    id='phone'
                    type='tel'
                    placeholder='DDD + número'
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('telefone', Masks.MaskPHONE(event.currentTarget.value))}
                  />
                  {errors.telefone && <p className='fs-12 mt-1 mb-0 text-erro'>{errors.telefone.message}</p>}
                </div>
              </div>

              <div className='col-12 mb-3'>
                <div className={errors.email ? 'error' : ''}>
                  <label htmlFor='email' className='fs-14 lh-18 fw-600 text-grayscale-400 d-block'>E-mail</label>
                  <input
                    ref={register({
                      required: 'E-mail obrigatório',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name='email'
                    id='email'
                    maxLength={80}
                    type='text'
                    placeholder='Informe o seu e-mail'
                  />
                  {errors.email &&
                    <p className='fs-12 mt-1 mb-0 text-erro'>{errors.email.message}</p>}
                </div>

              </div>

              <div className='col-12 col-md-6 mb-3'>
                <div className={errors.cep ? 'error' : ''}>
                  <label htmlFor='estado' className='fs-14 lh-18 fw-600 text-grayscale-400 d-block'>CEP</label>
                  <input
                    name='cep'
                    id='cep'
                    maxLength={80}
                    type='text'
                    placeholder='Digite apenas números'
                    value={cep}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setCep(Masks.MaskCEP(event.target.value))}
                    ref={register({
                      required: 'CEP Obrigatório',
                    })}
                  />
                  {errors.cep &&
                    <p className='fs-12 mt-1 mb-0 text-erro'>{errors.cep.message}</p>}
                </div>
              </div>

              <div className='col-12 col-md-6 mb-3'>
                <div className={errors.contato ? 'error' : ''}>
                  <label htmlFor='estado' className='fs-14 lh-18 fw-600 text-grayscale-400 d-block'>Para quem é o contato?</label>
                  <div className='d-flex align justify-content-between'>
                    <label className='radio-contato' htmlFor='paraMim'>
                      <input
                        ref={register({
                          required: 'Contato obrigatório',
                        })}
                        type='radio'
                        id='paraMim'
                        name='contato'
                        value='Para quem é o contato : para mim'
                      />
                      <span className='radio-check' />
                      Para mim
                    </label>
                    <label className='radio-contato' htmlFor='paraEmpresa'>
                      <input
                        ref={register({
                          required: 'Contato obrigatório',
                        })}
                        type='radio'
                        id='paraEmpresa'
                        name='contato'
                        value='Para quem é o contato: para minha empresa'
                      />
                      <span className='radio-check' />
                      Para minha empresa
                    </label>
                  </div>
                  {errors.contato &&
                    <p className='fs-12 mt-1 mb-0 text-erro'>{errors.contato.message}</p>}
                </div>
              </div>

              <div className={`col-12 pt-2 d-flex flex-column ${errors.range && 'error'}`}>
                <span className='fs-14 lh-17 fs-md-16 text-grayscale-400 text-left mb-3 fw-600 d-block'>Quanto pretende investir?</span>
                <div className='col px-0'>
                  <RangeInput
                    minValue={0}
                    maxValue={5000000}
                    onChange={changeRange}
                    interval={500000}
                    percentageRange={PercentageInterWinJSON}
                    register={register({
                      required: 'Marque o quanto quer investir',
                      validate: {
                        min: (value: number) => value >= 1000000,
                      },
                    })}
                  />
                </div>
                <div className='d-flex justify-content-between align-items-center flex-column'>
                  <p className='text-value text-grayscale-500 fs-12 lh-15 fw-400'>
                    {intervalJSON[rangeValue.toString() as keyof typeof intervalJSON]}
                  </p>
                </div>
                {errors.investir && <p className='fs-12 mt-1 mb-0 text-erro'>Marque o quanto quer investir</p>}
              </div>

              <div className='col-12 d-flex mb-2 px-0 mb-3'>
                <input
                  ref={register()}
                  className='formInputCheck d-none'
                  id='aceite'
                  name='aceito'
                  type='checkbox'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => handleCheck(event)}
                />
                <label className='formLabelCheck fs-12 lh-15 text-grayscale-400' htmlFor='aceite'>
                  Autorizo o Inter a tratar meus dados para entrar em contato sobre me tornar cliente Win e estou de acordo com a {' '}
                  <a
                    className='text-orange--base fw-700'
                    title='Acessar Política de Privacidade do Banco Inter'
                    href='https://inter.co/politica-de-privacidade/'
                    target='_blank'
                    rel='noreferrer'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: dataLayer.section,
                        section_name: dataLayer.section_name,
                        element_action: 'click button',
                        element_name: 'Política de Privacidade.',
                        redirect_url: 'https://inter.co/politica-de-privacidade/',
                      })
                    }}
                  >
                    Política de Privacidade.
                  </a>
                </label>
              </div>

              <div className='col-12 mx-auto mt-1 mt-md-1 px-0 mb-3 '>
                <div className='row'>
                  <div className='col-6'>
                    <SendButton
                      reverse
                      type='button'
                      title='Enviar'
                      onClick={() => {
                        handleClient()
                        sendDatalayerEvent({
                          section: dataLayer.section,
                          section_name: dataLayer.section_name,
                          element_action: 'click button',
                          element_name: 'Já sou cliente Inter',
                        })
                      }}
                      disabled={loading}
                    >
                      {loading ? 'ENVIANDO...' : 'Já sou cliente Inter'}
                    </SendButton>
                  </div>
                  <div className='col-6'>
                    <SendButton
                      type='submit'
                      title='Enviar'
                      onClick={() => setIsClient(false)}
                      disabled={!acceptedTerms || loading}
                    >
                      {loading ? 'ENVIANDO...' : 'Continuar'}
                    </SendButton>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </>
  )
}

export default OpportunitiesForm
