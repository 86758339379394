import React, { ChangeEvent, LegacyRef, useState } from 'react'
import { RangeInputContainer } from './style'

type RangeInputProps = {
  minValue: number;
  maxValue: number;
  interval: number;
  onChange: Function;
  percentageRange: { [key: number]: number };
  register?: LegacyRef<HTMLInputElement> | undefined;
}

function currencyToFloat (currencyNumber: string) {
  const result = currencyNumber === '' ? 0 : parseInt(currencyNumber.replace(/\D/g, ''), 10)
  return result
}

const RangeInput = ({ minValue, maxValue, interval, onChange, percentageRange, register }: RangeInputProps) => {
  const [ rangeValue, setRangeValue ] = useState<number>(minValue)

  const changeRange = (event: ChangeEvent<HTMLInputElement>) => {
    setRangeValue(currencyToFloat(event.target.value))
    onChange(event.target.value)
  }

  return (
    <RangeInputContainer>
      <div>
        <input
          type='range'
          name='range'
          className='slider'
          min={minValue}
          max={maxValue}
          step={interval}
          value={rangeValue}
          onChange={changeRange}
          ref={register}
          style={{ background: `linear-gradient(90deg, rgba(255,122,0,1) ${percentageRange[rangeValue]}%, rgba(222,223,228) ${percentageRange[rangeValue]}%)` }}
        />
      </div>
    </RangeInputContainer>
  )
}

export default RangeInput
